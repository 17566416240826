/*!
 * Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

$include-column-box-sizing: true !default;

@import 'functions';
@import 'variables';

@import 'mixins/lists';
@import 'mixins/breakpoints';
@import 'mixins/container';
@import 'mixins/grid';
@import 'mixins/utilities';

@import 'vendor/rfs';

@import 'containers';
@import 'grid';

@import 'utilities';
// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-get-multiple(
  $utilities,
  (
    'display',
    'order',
    'flex',
    'flex-direction',
    'flex-grow',
    'flex-shrink',
    'flex-wrap',
    'justify-content',
    'align-items',
    'align-content',
    'align-self',
    'margin',
    'margin-x',
    'margin-y',
    'margin-top',
    'margin-end',
    'margin-bottom',
    'margin-start',
    'negative-margin',
    'negative-margin-x',
    'negative-margin-y',
    'negative-margin-top',
    'negative-margin-end',
    'negative-margin-bottom',
    'negative-margin-start',
    'padding',
    'padding-x',
    'padding-y',
    'padding-top',
    'padding-end',
    'padding-bottom',
    'padding-start'
  )
);

@import 'utilities/api';

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1920px,
);
// scss-docs-end grid-breakpoints

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1680px,
);
// scss-docs-end container-max-widths
