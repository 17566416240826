@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-card($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);
  $foreground: map.get($color, foreground);
  $typography: material.m2-get-typography-config($theme);

  $app-gutters: map.get($theme, app-gutters);

  .app-card {
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: map.get($app-gutters, 4);
    border-radius: 1rem;
    color: material.m2-get-color-from-palette($foreground, text);
    background-color: material.m2-get-color-from-palette($background, app-card-background);
    position: relative;
    overflow: hidden;
    &.has-background-opacity {
      background-color: rgba(material.m2-get-color-from-palette($background, app-card-background), 0.4);
    }

    & > .app-card-background {
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      width: auto;
      height: 100%;
      opacity: 0.3;
      color: inherit;
    }

    & > .app-card-header {
      display: flex;

      .app-card-header-icon {
        height: 32px;
        width: 32px;
        font-size: 32px;
        margin-right: 0.25rem;
      }

      .app-card-header-content {
        flex: 1;
      }
    }

    .app-card-pretitle {
      color: material.m2-get-color-from-palette($foreground, secondary-text);
      display: block;
      @include material.m2-typography-level($typography, 'caption');
      font-weight: bold;
      text-transform: uppercase;
    }

    .app-card-title {
      display: block;
      @include material.m2-typography-level($typography, 'subtitle-1');
      text-transform: uppercase;
      margin: 0;
    }

    & > .app-card-close {
      cursor: pointer;
      margin-left: auto;
      align-self: baseline;
    }

    & > .app-card-content {
      overflow: auto;
      padding: 1px 0;
    }

    &:has(.app-card-header) {
      & > .app-card-content {
        margin-top: 1rem;
      }
    }

    &:has(.app-card-footer) {
      & > .app-card-content {
        margin-bottom: 1rem;
      }
    }

    & > .app-card-footer {
      flex: 0 0 auto;
      margin-top: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      overflow: hidden;

      @include app-screen.screen-xs-only() {
        padding-top: 0.5rem;
      }
    }
  }
}
