@use 'sass:map';
@use '@angular/material';

@mixin app-character-pagination-item($theme) {
  $color: material.m2-get-color-config($theme);
  $accent: map.get($color, accent);
  $background: map.get($color, background);

  .app-character-pagination-item {
    display: block;
    border-radius: 1rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .paging-item {
      cursor: pointer;
      display: flex;
    }

    .pagint-item-text {
      flex: 1;
    }

    &.active .pagint-item-text {
      color: material.m2-get-color-from-palette($accent);
    }

    .paging-item-page {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .paging-item-page-value {
      color: material.m2-get-color-from-palette($accent);
      background-color: material.m2-get-color-from-palette($background, app-background);
      border-radius: 100%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      font-weight: bold;

      &.initial {
        border: 1px solid material.m2-get-color-from-palette($accent);
      }

      &.done {
        color: #ffffff;
        background-color: material.m2-get-color-from-palette($accent);
      }

      &.warning {
        background-color: material.m2-get-color-from-palette($background, app-notification-warning-text);
        color: #ffffff;
      }
    }

    .paging-line {
      position: absolute;
      top: 0;
      right: 2rem;
      width: 1px;
      background-color: #e5e5e5;
      height: 100%;
      z-index: -1;
    }
  }
}
