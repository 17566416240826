@use 'sass:map';
@use '@angular/material';

@mixin app-ability-card($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);
  $accent: map.get($color, accent);
  $typography: material.m2-get-typography-config($theme);

  .app-ability-card {
    display: flex;
    align-items: center;

    .app-ability-card-value {
      @include material.m2-typography-level($typography, 'headline-6');
      font-weight: bold;
      flex: 0 0 auto;
      margin-left: auto;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid material.m2-get-color-from-palette($background, app-border);
      border-radius: 0.5rem;
      position: relative;

      .app-ability-card-value-badge {
        color: material.m2-get-color-from-palette($accent);
        position: absolute;
        top: 0.25rem;
        right: 0.125rem;
        width: 0.5rem;
        min-width: 0.5rem;
        height: 0.5rem;
        font-size: 18px;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
