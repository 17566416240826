@use 'sass:map';
@use '@angular/material';

@mixin app-footer($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-footer {
    --mat-toolbar-container-background-color: #{material.m2-get-color-from-palette($background, app-background-bright)};
    height: 74px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
