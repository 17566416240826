@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-pages($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-pages {
    .app-pages-container {
      position: fixed;
      overflow: auto;
      top: 55px;
      right: 0;
      bottom: 1rem;
      left: 0;

      @media (min-width: 600px) {
        top: 64px;
      }
      @include app-screen.screen-lg() {
        bottom: 74px;
      }
    }

    .app-pages-feedback-button {
      display: none;
      position: fixed;
      bottom: 4rem;
      right: 2rem;

      @include app-screen.screen-lg() {
        display: flex;
      }
      @include app-screen.screen-xs-only() {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      @media (min-width: 1430px) {
        bottom: 6rem;
      }
    }
  }
}
