@use 'sass:map';
@use '@angular/material';

@mixin app-toolbar($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    --mat-toolbar-container-background-color: #{material.m2-get-color-from-palette($background, app-background-bright)};

    .navigation-disabled {
      color: var(--mdc-text-button-disabled-label-text-color) !important;
      .mat-icon {
        color: var(--mdc-text-button-disabled-label-text-color) !important;
      }
    }

    .logo-img {
      height: 48px;
      width: 48px;
    }

    @media (min-width: 600px) {
      .logo-img {
        height: 56px;
        width: 56px;
      }
    }
  }
}
