@use 'sass:map';
@use '@angular/material';

@mixin app-utils($theme) {
  $color: material.m2-get-color-config($theme);
  $accent: map.get($color, accent);
  $background: map.get($color, background);
  $foreground: map.get($color, foreground);
  $app-school-colors: material.m2-get-color-from-palette($background, app-school-colors);
  $app-rarity-colors: material.m2-get-color-from-palette($background, app-rarity-colors);
  $app-effect-colors: material.m2-get-color-from-palette($background, app-effect-colors);
  $app-action-colors: material.m2-get-color-from-palette($background, app-action-colors);
  $app-base-colors: material.m2-get-color-from-palette($background, app-base-colors);
  $app-class-colors: material.m2-get-color-from-palette($background, app-class-colors);
  $app-coin-colors: material.m2-get-color-from-palette($background, app-coin-colors);

  // TEXT

  .app-text-nowrap {
    text-wrap: nowrap;
  }

  .app-text-secondary {
    color: material.m2-get-color-from-palette($foreground, secondary-text);
  }

  .app-text-accent {
    color: material.m2-get-color-from-palette($accent) !important;
  }

  .app-text-warning {
    color: material.m2-get-color-from-palette($background, app-notification-warning-text) !important;
  }

  .app-text-uppercase {
    text-transform: uppercase;
  }

  .app-text-center {
    text-align: center;
  }

  .app-text-bold {
    font-weight: 600 !important;
  }

  .app-text-italic {
    font-style: italic;
  }

  .app-text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .mat-mdc-tooltip-trigger {
    touch-action: auto !important;
  }

  .app-text-right {
    text-align: right;
  }

  .app-text-center {
    text-align: center;
  }

  // ICONS

  .app-icon-sm {
    font-size: 18px;
    width: 18px !important;
    height: 18px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .app-icon-lg {
    --mdc-icon-button-icon-size: 40px;
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .mat-icon-button .app-icon-sm,
  .mat-icon-button .app-icon-lg {
    margin: auto;
    margin-right: auto !important;
  }

  .app-bg-octagon {
    -webkit-mask: url('/assets/background/octagon.svg') no-repeat center;
    mask: url('/assets/background/octagon.svg') no-repeat center;
    background-color: rgba(0, 0, 0, 0.05);

    height: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-bg-hexagon {
    -webkit-mask: url('/assets/background/hexagon.svg') no-repeat center;
    mask: url('/assets/background/hexagon.svg') no-repeat center;
    background-color: rgba(0, 0, 0, 0.05);

    height: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // OPACITY

  .app-opacity-1 {
    opacity: 0.75;
  }

  .app-opacity-2 {
    opacity: 0.5;
  }

  .app-opacity-3 {
    opacity: 0.25;
  }

  // WIDTH & HEIGHT & FLEX
  .app-w-100 {
    width: 100%;
  }

  .app-h-100 {
    height: 100%;
  }

  .app-flex-1 {
    flex: 1;
  }

  .app-order-n1 {
    order: -1;
  }

  // BACKGROUND COLOR / COLOR: SCHOOLS, RARITY, EFFECT

  .app-bg-accent {
    background-color: material.m2-get-color-from-palette($accent) !important;
    color: material.m2-get-color-from-palette($accent, default-contrast) !important;
  }

  .app-bg-accent-disabled {
    background-color: rgba($color: material.m2-get-color-from-palette($accent), $alpha: 0.5) !important;
    color: material.m2-get-color-from-palette($accent, default-contrast) !important;
  }

  .app-bg-default {
    background-color: material.m2-get-color-from-palette($background, app-background);
  }

  .app-bg-base {
    background-color: rgba(material.m2-get-color-from-palette($background, app-background), 0.95);
  }

  .app-bg-card {
    background-color: material.m2-get-color-from-palette($background, app-card-background);
  }

  .app-bg-table-odd {
    background-color: material.m2-get-color-from-palette($background, app-markdown-table-row-odd-background);
  }
  .app-bg-table-even {
    background-color: material.m2-get-color-from-palette($background, app-markdown-table-row-even-background);
  }

  @each $name, $color in $app-school-colors {
    .app-bg-#{'' + $name} {
      background-color: #{$color} !important;
    }
  }

  @each $name, $color in $app-rarity-colors {
    .app-color-#{'' + $name} {
      color: #{$color} !important;
    }
    .app-bg-#{'' + $name} {
      background-color: rgba($color, 0.1) !important;
    }
  }

  @each $name, $color in $app-effect-colors {
    .app-color-#{'' + $name} {
      color: #{$color} !important;
    }
    .app-bg-#{'' + $name} {
      background-color: #{$color} !important;
    }
  }

  @each $name, $color in $app-action-colors {
    .app-color-#{'' + $name} {
      color: #{$color} !important;
    }
  }

  @each $name, $color in $app-base-colors {
    .app-color-#{'' + $name} {
      color: #{$color} !important;
    }
    .app-bg-#{'' + $name} {
      background-color: #{$color} !important;
      color: material.m2-get-color-from-palette($background, app-text-contrast);
    }
  }

  @each $name, $color in $app-class-colors {
    .app-bg-#{'' + $name} {
      background-color: #{$color} !important;
      color: material.m2-get-color-from-palette($background, app-text-contrast);
    }
    .app-color-#{'' + $name} {
      color: #{$color} !important;
    }
  }

  @each $name, $color in $app-coin-colors {
    .app-color-#{'' + $name} {
      color: #{$color} !important;
    }
    .app-bg-#{'' + $name} {
      background-color: rgba($color, 0.1) !important;
    }
  }

  // OTHER

  .app-pointer {
    cursor: pointer;
  }

  .app-border {
    border: 1px solid material.m2-get-color-from-palette($background, app-border);
  }

  .app-border-none {
    border: none;
  }

  .app-header-sticky {
    position: sticky;
    top: 0;
    z-index: 200;
  }

  .app-header-sticky-2 {
    position: sticky;
    top: 30px;
    z-index: 200;
  }

  // todo should be done via angular animation, because css triggers transition after rerendering
  .app-card-selectable {
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  }
}
