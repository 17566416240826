@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-srd-page($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);
  $typography: material.m2-get-typography-config($theme);

  .app-srd-page {
    padding-top: 0;

    --app-srd-page-grid-panel: 300px;
    @include app-screen.screen-lg-only() {
      --app-srd-page-grid-panel: 200px;
    }

    .app-srd-page-grid {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: var(--app-srd-page-grid-panel) 1fr;
      grid-template-rows: auto;
      gap: 1rem;

      .app-srd-page-grid-panel {
        grid-column: 1 / 2;

        .app-srd-page-grid-panel-inner {
          position: sticky;
          top: calc(58px + 3.5rem);
          z-index: 10;
        }
      }

      .app-srd-page-grid-content {
        grid-column: 2 / 3;
        max-width: 100%;
        overflow: hidden;
      }
    }

    .app-srd-page-header {
      padding: 1rem 1rem 0 1rem;
      margin: 0 -1rem;
      width: calc(100% + 2rem);
      background-color: material.m2-get-color-from-palette($background, app-card-background);

      @include app-screen.screen-lg() {
        padding: 2rem 1rem 1rem 1rem;
        position: sticky;
        top: 0;
        z-index: 20;
        background: linear-gradient(
          0,
          transparent 0,
          rgba(material.m2-get-color-from-palette($background, app-background), 0.5) 9px,
          material.m2-get-color-from-palette($background, app-background) 18px,
          material.m2-get-color-from-palette($background, app-background) 0
        );
      }
    }

    .app-srd-page-navbar-container {
      position: sticky;
      top: 0;
      margin: 0 -1rem;
      width: calc(100% + 2rem);
      padding-top: 1px;
      height: 58px;
      transition: height 0.3s ease-in-out;
      overflow: hidden;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 2px 2px 0px rgba(0, 0, 0, 0.12);
      background-color: material.m2-get-color-from-palette($background, app-card-background);
      z-index: 20;

      &.app-srd-page-navbar-container-searchable {
        height: 114px;
      }

      .app-navbar,
      .app-form-field {
        box-shadow: none;
      }
    }

    .app-srd-page-title {
      font-size: 8vw;
      word-wrap: break-word;
      text-transform: uppercase;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      @include app-screen.screen-sm() {
        @include material.m2-typography-level($typography, 'headline-5');
      }
    }

    .app-srd-page-preview-card {
      position: relative;
      border-radius: 1rem;
      line-height: 0;
      padding: 0;
      overflow: hidden;
      @include material.elevation(2);
      background-color: material.m2-get-color-from-palette($background, app-card-background);

      .app-srd-page-preview-card-icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 56px;
        width: 56px;
        height: 56px;
      }

      .app-srd-page-preview-card-background-image {
        width: 100%;
        height: auto;
      }
    }

    .app-srd-page-chart-card.app-card {
      @include material.elevation(2);
      padding: 0.5rem;
      margin-top: 1rem;
      @include app-screen.screen-sm() {
        margin-top: 0;
      }
      @include app-screen.screen-lg() {
        margin-top: 1rem;
      }
    }
  }
}
